import { useEffect, useCallback, useState } from 'react';

// WRM
import { requestApi } from 'api/request-api';
import ListResources from 'components/shared/ListResources';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import { sectionChoices, myAccountSectionChoices } from './constants';

const title = 'Resource pages';
const apiEndpoint = 'resource-pages';
const resourceName = 'resource page';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    formatter: (resource) => `${resource.name}${resource.isArchived ? ' (archived)' : ''}`,
  },
  {
    field: '_parentResourcePageName',
    label: 'Parent page',
    formatter: (resource) => resource.parentResourcePage?.name,
  },
  {
    field: 'section',
    label: 'Website / My Account section',
    formatter: (resource) => {
      const textParts = [];
      const selectedSectionChoice = sectionChoices.find((sectionChoice) => sectionChoice.value === resource.section);
      textParts.push(selectedSectionChoice?.label ?? 'None');
      const selectedMyAccountSectionChoice = myAccountSectionChoices.find((myAccountSectionChoice) => myAccountSectionChoice.value === resource.myAccountSection);
      if (selectedMyAccountSectionChoice) {
        textParts.push(selectedMyAccountSectionChoice.label);
      }
      return textParts.join(' / ');
    },
  },
  {
    field: '_departments',
    label: 'Department(s)',
    formatter: (resource) => {
      if(resource.departments.length === 0) {
        return '-';
      }
      return resource.departments.map(d => d.name).join(', ');
    }
  },
  {
    field: 'uri',
    label: 'Link',
    sortable: true,
  },
];

const filters = [
  {
    field: '_resourcePageSearch',
    label: 'Search',
    type: 'text',
    apiAttribute: 'resourcePageSearch',
  },
  {
    field: 'parentPage',
    label: 'Parent page',
    type: 'select',
  },
  {
    field: 'section',
    label: 'Website section',
    type: 'select',
    choices: sectionChoices,
  },
  {
    field: 'department',
    label: 'Department',
    type: 'select',
    apiAttribute: 'departments',
  },
  {
    field: '_topLevelOnly',
    label: 'Top level only',
    type: 'checkbox',
    apiAttribute: 'exists[parentPage]',
    apiValue: 'false'
  },
  {
    field: '_excludeArchived',
    label: 'Exclude archived',
    type: 'checkbox',
    apiAttribute: 'isArchived',
    apiValue: 'false',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const ResourcePages = () => {
  const [initialised, setInitialised] = useState(false);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (isMounted()) {
      setShowLoadingSpinner(true);
      const noChoice = { value: '', label: '--- Remove filter ---' }
      const departmentChoices = await requestApi.getResponse({ url: 'departments/choices' });
      const departmentsFilter = filters.find((filter) => filter.field === 'department');
      departmentsFilter.choices = [noChoice, ...departmentChoices];
      const resourcePageChoices = await requestApi.getResponse({ url: 'resource-pages/choices' });
      const parentPageFilter = filters.find((filter) => filter.field === 'parentPage');
      parentPageFilter.choices = [noChoice, ...resourcePageChoices];
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  return (
    <ListResources
      apiEndpoint={apiEndpoint}
      columns={columns}
      defaultSortValues={defaultSortValues}
      filters={filters}
      resourceName={resourceName}
      title={title}
    />
  );
};

export default ResourcePages;
